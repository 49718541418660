<template>
  <ion-app>
    <ion-split-pane content-id="menu-content" :disabled="!auth.idToken">

      <ion-menu content-id="menu-content" :swipeGesture="false">
        <ion-content :force-overscroll="false">
          <div style="display: flex;flex-direction: column; height: 100%;">
            <div style="flex:1">
              <ion-list lines="none">

                <ion-menu-toggle :auto-hide="false">
                  <ion-item :button="menuConfiguration.showContracts"
                    :router-link="menuConfiguration.showContracts ? '/contracts' : '/'"
                    :class="{ selected: isActiveUrl('/contracts') }">
                    <ion-avatar slot="start">
                      <img :src="profilePicture" />
                    </ion-avatar>
                    <ion-label>
                      <h2>{{ user ? user.user?.personnel.userName : '' }}</h2>
                      <p>{{ user ? user.user?.contracts.map((c: any) => c.type).join(', ') : '' }}</p>
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>

              <ion-list lines="none">
                <ion-menu-toggle :auto-hide="false">
                  <ion-item button router-link="/allocations" :class="{ selected: isActiveUrl('/allocations') }">
                    <div slot="start" class="icon-wrapper" :style="{ backgroundColor: '#3880ff' }">
                      <i class="fa fa-user-helmet-safety fa-lg fa-fw"></i>
                    </div>
                    <ion-label>{{ $t('p.home.allocation') }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle :auto-hide="false" v-if="menuConfiguration.showCounters">
                  <ion-item button router-link="/counters" :class="{ selected: isActiveUrl('/counters') }">
                    <div slot="start" class="icon-wrapper" :style="{ backgroundColor: '#2dd36f' }">
                      <i class="fa fa-calculator fa-lg fa-fw"></i>
                    </div>
                    <ion-label>{{ $t('p.home.counter') }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle :auto-hide="false" v-if="menuConfiguration.showTeams">
                  <ion-item button router-link="/teams" :class="{ selected: isActiveUrl('/teams') }">
                    <div slot="start" class="icon-wrapper" :style="{ backgroundColor: '#ffc409' }">
                      <i class="fa fa-users fa-fw"></i>
                    </div>
                    <ion-label>{{ $t('p.home.team') }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>

              <ion-list lines="none" v-if="menuConfiguration.showSkills">
                <ion-menu-toggle :auto-hide="false" v-for="c in categories" :key="c.id">
                  <ion-item button :router-link="`/skill/${c.id}`" :class="{ selected: isActiveUrl(`/skill/${c.id}`) }">
                    <div slot="start" class="icon-wrapper" :style="{ backgroundColor: c.color || '#555' }">
                      <i class="fa fa-bookmark fa-fw"></i>
                    </div>
                    <ion-label>{{ c.name }}</ion-label>
                    <ion-badge slot="end">{{ c.count }}</ion-badge>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>

              <ion-list lines="none" v-if="menuConfiguration.showTrainings && hasTrainingLicense">
                <ion-menu-toggle :auto-hide="false">
                  <ion-item button router-link="/trainings" :class="{ selected: isActiveUrl('/trainings') }">
                    <div slot="start" class="icon-wrapper" :style="{ backgroundColor: '#5260ff' }">
                      <i class="fa fa-book fa-fw"></i>
                    </div>
                    <ion-label>{{ $t('p.home.training') }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>

              
              <ion-list lines="none">
                <ion-menu-toggle :auto-hide="false">
                  <ion-item button router-link="/settings" :class="{ selected: isActiveUrl('/settings') }">
                    <div slot="start" class="icon-wrapper" :style="{ backgroundColor: '#777' }">
                      <i class="fa fa-cog fa-fw"></i>
                    </div>
                    <ion-label>{{ $t('p.home.settings') }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>

            </div>
            <div style="margin-left: 10px; margin-right: 10px;">
              <ion-button expand="block" @click="onLogout">{{ $t('p.home.logout') }}</ion-button>
            </div>
          </div>
        </ion-content>
      </ion-menu>

      <ion-router-outlet :animated="false" id="menu-content" :swipeGesture="false"></ion-router-outlet>
    </ion-split-pane>

  </ion-app>
</template>

<script lang="ts" setup>
import {
  IonApp, IonItem, IonLabel, IonRouterOutlet, IonContent, IonMenuToggle, IonList, IonSplitPane, IonAvatar,
  IonMenu, IonBadge, IonButton,
} from '@ionic/vue';
import { ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from './stores/auth.store';
import { useUserStore } from './stores/user.store';
import { getApi } from '@/helpers/api';
import { PeriodSkillsApi, PeriodTrainingsApi, DtoSkillCategory, MyPpfConfigApi, GetMenuResponse } from 'ppf-api-client';


const categories = ref<DtoSkillCategory[]>([]);
const auth = useAuthStore();
const route = useRoute();
const isActiveUrl = (path: string) => path === route.fullPath;
const user = useUserStore();
const profilePicture = ref('/assets/img/default-user-icon.jpg');
const hasTrainingLicense = ref<boolean>(false);
const menuConfiguration = ref<GetMenuResponse>({
  showContracts: false,
  showSkills: false,
  showTeams: false,
  showTrainings: false,
  showCounters: false,
});
const skillService =  getApi(PeriodSkillsApi);
const trainingService = getApi(PeriodTrainingsApi);
const configService = getApi(MyPpfConfigApi);

//logout callback
const onLogout = function () {
  auth.logout();
};


//watch er on auth store. Triggered immediately after a change
watchEffect(async () => {
  if (auth.isLogged) {
    if (skillService) {
      categories.value = await skillService.periodSkillsCategoriesFromDate(auth.userId, new Date());
      trainingService.periodTrainingsHasLicense().then(v => hasTrainingLicense.value = v);
      configService.myPpfConfigGetMenu().then(v => menuConfiguration.value = v);

    }
  }
});
watchEffect(async () => {
  if (user.user?.profilePicture) {
    profilePicture.value = user.user.profilePicture;
  }
});
</script>


<style scoped>
ion-menu ion-content {
  --padding-top: 20px;
  --padding-bottom: 20px;

  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/* Remove background transitions for switching themes */
ion-menu ion-item {
  --transition: none;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

/*
 * Material Design Menu
*/
ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-list-header {
  padding-left: 18px;
  padding-right: 18px;

  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 450;
}

ion-menu.md ion-item {
  --padding-start: 18px;

  margin-right: 10px;

  border-radius: 0 50px 50px 0;

  font-weight: 500;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-list-header,
ion-menu.md ion-item ion-icon {
  color: var(--ion-color-step-650, #5f6368);
}

ion-menu.md ion-list:not(:last-of-type) {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}


/*
 * iOS Menu
*/
ion-menu.ios ion-list-header {
  padding-left: 16px;
  padding-right: 16px;

  margin-bottom: 8px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}






.icon-wrapper {
  width: 30px;
  height: 30px;
  background-color: gray;
  color: white;
  border-radius: 5px;
  text-align: center;
  padding: 6px 0px;
}
</style>