<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $t('p.contract.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-list>
        <ion-item v-for="c in contracts" v-bind:key="c.id">
          <i slot="start" class="fa fa-circle fa-sm fa-fw" :style="{ color: c.color || '#000' }"></i>
          <ion-label>
            <h2>{{ c.type }}</h2>
            <p>{{ rangeDateText(c.start, c.end) }}</p>
          </ion-label>
          <ion-label slot="end">
            <p>{{ dayjs.duration(c.duration || '0').asHours() }}h</p>
          </ion-label>
        </ion-item>
      </ion-list>

      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || contracts.length > 0 ? 'none' : 'block' }" icon="fa-file-signature">
        {{ $t('p.contract.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle,
  IonToolbar, IonList, IonItem, IonLabel, onIonViewDidEnter,
} from '@ionic/vue';
import { ref } from 'vue';
import dayjs from 'dayjs'
import { useAuthStore } from '@/stores/auth.store';
import EmptyState from './components/EmptyState.vue'
import ListSkeletton from './components/ListSkeletton.vue'
import { rangeDateText } from '@/helpers/date';
import { getApi } from '@/helpers/api';
import { PeriodContractsApi, DtoContract } from 'ppf-api-client';


const contracts = ref<DtoContract[]>([]);
const loading = ref(true);

const service = getApi(PeriodContractsApi);
const auth = useAuthStore();

//load inital data when entering the tab
onIonViewDidEnter(() => {
  loading.value = true;
  contracts.value = [];

  service.periodContractsByPersonnel(auth.userId).then(r => {
    contracts.value = r.sort((a, b) => {
      const at = a.start ? a.start.getTime() : 0;
      const bt = b.start ? b.start.getTime() : 0;
      return bt - at
    });
    loading.value = false;
  });
});
</script>

<style scoped></style>
