<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ category?.name }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-segment :value="filter" @ionChange="filterChanged">
        <ion-segment-button value="current">
          {{ $t('g.current') }}
        </ion-segment-button>
        <ion-segment-button value="history">
          {{ $t('g.history') }}
        </ion-segment-button>
      </ion-segment>

      <ion-list>
        <ion-item v-for="c in filteredList" v-bind:key="c.id">
          <ion-label>
            <h2>{{ c.name }}</h2>
            <p :class="{ disabled: c.disabled }">{{ rangeDateText(c.start, c.end) }}</p>
          </ion-label>
          <ion-badge slot="end" color="medium">{{ c.state }}</ion-badge>
        </ion-item>
      </ion-list>

      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || filteredList.length > 0 ? 'none' : 'block' }" icon="fa-certificate">
        {{ $t('p.skill.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle, IonBadge,
  IonToolbar, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton, onIonViewDidEnter
} from '@ionic/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import EmptyState from './components/EmptyState.vue'
import ListSkeletton from './components/ListSkeletton.vue'
import { rangeDateText } from '@/helpers/date';
import { getApi } from '@/helpers/api';
import { PeriodSkillsApi, DtoSkillCategory, DtoSkill } from 'ppf-api-client';

//route parameters
const props = defineProps({
  //category to display skill list for
  categoryId: Number
})

let skills: DtoSkill[] = [];
const loading = ref(true);
const filter = ref<'current' | 'history'>('current');
const filteredList = ref<DtoSkill[]>([]);
const category = ref<DtoSkillCategory | null>(null);

const service = getApi(PeriodSkillsApi);
const auth = useAuthStore();
const now = new Date();

//load inital data when entering the tab
onIonViewDidEnter(() => {
  if (props.categoryId) {
    loading.value = true;
    skills = [];
    
    service.periodSkillsCategory(props.categoryId).then(r => category.value = r);

    service.periodSkillsGetByCategory(props.categoryId, auth.userId, new Date()).then(r => {
      skills = r;
      loading.value = false;
      reloadList();
    });

  }
});

//filter change callback: reload list
const filterChanged = (e: any) => {
  filter.value = e.target.value;
  reloadList();
}

//reload list by reapplying filter and sort
const reloadList = () => {
  filteredList.value = skills
    .filter(c => filter.value == 'current' ? (!c.end || c.end >= now || c.end.getTime() == 0) : (c.end && c.end.getTime() > 0 && c.end < now))
    .sort((a, b) => {
      const at = a.start ? a.start.getTime() : 0;
      const bt = b.start ? b.start.getTime() : 0;
      return at - bt
    });
}

</script>

<style scoped>
ion-label>p.disabled {
  text-decoration: line-through;
}
</style>
