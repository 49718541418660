import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import ContractsPage from '../views/ContractsPage.vue'
import CountersPage from '../views/CountersPage.vue'
import LoginPage from '@/views/LoginPage.vue';
import TeamsPage from '@/views/TeamsPage.vue';
import TrainingsPage from '@/views/TrainingsPage.vue';
import SkillsPage from '@/views/SkillsPage.vue';
import SettingsPage from '@/views/SettingsPage.vue';
import AllocationsPage from '@/views/AllocationsPage.vue';
import AllocationsDayPage from '@/views/AllocationsDayPage.vue';
import { useAuthStore } from '@/stores/auth.store';

/**
 * All routes of the app
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/allocations'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: ContractsPage,
  },
  {
    path: '/counters',
    name: 'counter',
    component: CountersPage,
  },
  {
    path: '/teams',
    name: 'teams',
    component: TeamsPage,
  },
  {
    path: '/trainings',
    name: 'trainings',
    component: TrainingsPage,
  },
  {
    path: '/allocations',
    name: 'allocations',
    component: AllocationsPage,
  },
  {
    path: '/allocationsDay/:start',
    name: 'allocationsDay',
    component: AllocationsDayPage,
    props: true,
  },
  {
    path: '/skill/:categoryId',
    name: 'skill',
    component: SkillsPage,
    //props can receive a function to modify route params before being passed to the component
    props: function(route:any) {
      return {
        categoryId: Number(route.params.categoryId),
      };
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*
 * authentication router guard
 * redirects to login if no logged 
 */
router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  
  if (authRequired && !auth.idToken) {
      auth.returnUrl = to.fullPath;
      return '/login';
  } else if (!authRequired && auth.idToken) {
    return '/';
} 
});

export default router
