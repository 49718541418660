<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $t('p.training.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-segment :value="filter" @ionChange="filterChanged">
        <ion-segment-button value="current">
          {{ $t('g.current') }}
        </ion-segment-button>
        <ion-segment-button value="history">
          {{ $t('g.history') }}
        </ion-segment-button>
      </ion-segment>


      <ion-list>
        <ion-item v-for="c in filteredList" v-bind:key="c.id">
          <i slot="start" class="fa fa-circle fa-sm fa-fw" :style="{ color: c.color || '#000' }"></i>
          <ion-label>
            <h2 style="white-space: initial;">{{ c.domain }}</h2>
            <p>
              {{ c.state }} : {{ dayjs(c.start).format('L') }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>



      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || filteredList.length > 0 ? 'none' : 'block' }" icon="fa-book">
        {{ $t('p.training.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle, IonSegment, IonSegmentButton,
  IonToolbar, IonItem, IonLabel, IonList, onIonViewDidEnter
} from '@ionic/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import EmptyState from './components/EmptyState.vue'
import ListSkeletton from './components/ListSkeletton.vue'
import dayjs from 'dayjs'
import { getApi } from '@/helpers/api';
import { PeriodTrainingsApi, DtoTraining } from 'ppf-api-client';

const loading = ref(true);
let trainings: DtoTraining[] = [];
const filter = ref<'current' | 'history'>('current');
const filteredList = ref<DtoTraining[]>([]);

const service = getApi(PeriodTrainingsApi);
const auth = useAuthStore();

//load inital data when entering the tab
onIonViewDidEnter(() => {
  loading.value = true;
  trainings = [];

  service.periodTrainingsByPersonnel(auth.userId).then(r => {
    trainings = r;
    loading.value = false;
    reloadList();
  });
});

//filter change callback: reload list
const filterChanged = (e: any) => {
  filter.value = e.target.value;
  reloadList();
}

//reload list by reapplying filter and sort
const reloadList = () => {
  filteredList.value = trainings
    .filter(c => filter.value == 'current' ? c.baseStatus != 'Completed' : c.baseStatus == 'Completed')
    .sort((a, b) => {
      const at = a.start ? a.start.getTime() : 0;
      const bt = b.start ? b.start.getTime() : 0;
      return at - bt
    });
}


</script>

<style scoped>
.emphasis {
  font-weight: bold;
}

.fa.fa-person-running {
  color: blue;
}

.fa.fa-check {
  color: green;
}
</style>
