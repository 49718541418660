<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $t('p.team.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-segment :value="filter" @ionChange="filterChanged">
        <ion-segment-button value="current">
          {{ $t('g.current') }}
        </ion-segment-button>
        <ion-segment-button value="history">
          {{ $t('g.history') }}
        </ion-segment-button>
      </ion-segment>

      <ion-list>
        <ion-item v-for="c in filteredList" v-bind:key="c.id">
          <i slot="start" class="fa fa-circle fa-sm fa-fw" :style="{ color: c.color || '#000' }"></i>
          <ion-label>
            <h2>{{ c.name }}</h2>
            <p>{{ rangeDateText(c.start, c.end) }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || filteredList.length > 0 ? 'none' : 'block' }" icon="fa-users">
        {{ $t('p.team.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle,
  IonToolbar, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton, onIonViewDidEnter
} from '@ionic/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import EmptyState from './components/EmptyState.vue';
import ListSkeletton from './components/ListSkeletton.vue';
import { rangeDateText } from '@/helpers/date';
import { getApi } from '@/helpers/api';
import { PeriodTeamsApi, DtoPersonnelTeamLink } from 'ppf-api-client';


let teams: DtoPersonnelTeamLink[] = [];
const loading = ref(true);
const filter = ref<'current' | 'history'>('current');
const filteredList = ref<DtoPersonnelTeamLink[]>([]);

const service = getApi(PeriodTeamsApi);
const auth = useAuthStore();
const now = new Date();

//load inital data when entering the tab
onIonViewDidEnter(() => {
  loading.value = true;
  teams = [];
  
  service.periodTeamsByPersonnel(auth.userId).then(r => {
    teams = r;
    loading.value = false;
    reloadList();
  });
});

//filter change callback will reload the list
const filterChanged = (e: any) => {
  filter.value = e.target.value;
  reloadList();
}

//reload the list by applying the filter and default sort
const reloadList = () => {
  filteredList.value = teams
    .filter(c => filter.value == 'current' ? (!c.end || c.end >= now || c.end.getTime() == 0) : (c.end && c.end.getTime() > 0 && c.end < now))
    .sort((a, b) => {
      const at = a.start ? a.start.getTime() : 0;
      const bt = b.start ? b.start.getTime() : 0;
      return bt - at
    });
}

</script>

<style scoped></style>
