import { defineStore } from 'pinia';
import { useAuthStore } from './auth.store';
import { getApi } from '@/helpers/api';
import { PeriodPersonnelApi , DtoPersonnelDetail, MyPpfConfigApi} from 'ppf-api-client';

/**
 * user store
 */
export const useUserStore = () => {
    //Wrap the store in a closure so it initializes the store before returning it
    const innerStore = defineStore({
        id: "user",
        /**
         * contains the user model (null if not logged)
         */
        state: () => ({
            user: null as DtoPersonnelDetail|null,
        }),

        actions: {
            /**
             * Load user details from a personnel id
             * The call use the current time to get the contracts of the user timed on the present
             * @param personnelId id of personnel to get user details from 
             */
            async load(personnelId: number) {
                const u = await getApi(PeriodPersonnelApi).periodPersonnelDetails(personnelId, new Date());
                this.user = u;
            },
        },
    });

    //reload the information if logged in but not filled yet (checking the localstorage)
    const s = innerStore();
    const auth = useAuthStore();
    if (auth.userId >= 0 && !s.user) {
        s.load(auth.userId);
    }
    return s;
};




/**
 * subscribe the current user to push
 * Retrieve the VAPID public key from api and create a new push subscription if not already done
 * Post the subscription to the server so it can push messages
 * @param personnelId id of personnel the subscription is tied to
 */
export async function subscribeToPush(personnelId: number) {
    const registration = await navigator.serviceWorker.getRegistration();

    if (registration) {
        const sub = await registration.pushManager.getSubscription();

        //if already subscribed, unsusbscribe first
        if (sub) {
            await sub.unsubscribe();
        }

        //request a new permission
        const result = await window.Notification.requestPermission();
        if (result == "granted") {
            const VAPID_PUBLIC_KEY = await getApi(MyPpfConfigApi).myPpfConfigVapidPublicKeyWithHttpInfo()
                                            .then(x => x.body.text())
                                            .then(x => JSON.parse(x).vapidKey);
            const sub = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlB64ToUint8Array(VAPID_PUBLIC_KEY)
            });
            await getApi(PeriodPersonnelApi).periodPersonnelCreateSubscription(personnelId, JSON.stringify(sub.toJSON()))
        }
    }
}


/**
 * unsubscribe the current user from push
 * remove the push subscription from the push server and remove the stored
 * subscribption from the webapi server
 * @param personnelId the personnel to unsubscribe from push
 */
export async function unsubscribeFromPush(personnelId: number) {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
        //remove push subscription
        const pushSub = await registration.pushManager.getSubscription();
        pushSub?.unsubscribe();

        //remove server subscription
        await getApi(PeriodPersonnelApi).periodPersonnelRemoveSubscription(personnelId);
    }
}


/**
 * check if a user is subscribed from push
 * check if the browser holds a push subscription, then check the webapi has a reference to
 * a push subscription.
 * if either of those is false, the user won't be able to receive a notification so we return
 * false.
 * If there is a subscription but no reference to it on the server, the existing subscription is
 * removed as it is unusable and it needs to be removed before creating a new one.
 * @param personnelId the user to check the push subscription for
 * @returns a promise for a boolean (true if subscribed)
 */
export async function isSubscribedToPush(personnelId: number): Promise<boolean> {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
        //check push subscription
        const pushSub = await registration.pushManager.getSubscription();//TODO GMA shouldn't I use permissionState()?
        if (pushSub == null) {
            return false;
        }
        //check that the server has a push subscription
        const sub = await getApi(PeriodPersonnelApi).periodPersonnelGetSubscription(personnelId);
        if (!sub) {
            //remove pus sub if no reference to it on the server
            pushSub.unsubscribe();
        }
        return true;
    }

    return false;
}


// 
// 
/**
 * Utility functions: Convert a base64 string to Uint8Array.
 * Must do this so the server can understand the VAPID_PUBLIC_KEY.
 * 
 * @param base64String  to convert to byte array
 * @returns byte array representation of the param string
 */
function urlB64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        // eslint-disable-next-line 
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
