import { createApp } from 'vue'
import App from './App.vue'
import router from './helpers/router';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


import { loadLocaleMessages, setupI18n } from './helpers/i18n';
import { createPinia } from 'pinia';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import { getApi } from './helpers/api';
import { LocalizationApi } from 'ppf-api-client';


//initialize dayjs
dayjs.extend(localizedFormat);
dayjs.extend(duration);

//create app
const app = createApp(App)
  .use(IonicVue)
  .use(createPinia())
  .use(router);

//wait for router ready and config+mount the app
router.isReady().then(async () => {
  let locale = await getApi(LocalizationApi).localizationDefaultLocale();
  if (!locale) {
    locale = { locale: 'en' };
  }
  //TODO GMA can have typesafe locale files https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
  const i18n = setupI18n({
    legacy: false,
    locale: locale.locale, // set locale
    fallbackLocale: 'en', // set fallback locale
  });

  await loadLocaleMessages(i18n, locale.locale);
  await loadLocale(locale.locale);
  app.use(i18n)
  app.mount('#app');
  
  document.title = i18n.global.t('app.title');
});




//existing locales. Will be lazy loaded on demand and can come from nodejs or from locales folder
//dayjs locale files have to be converted to typescript
const locales = {
  fr: () => import('dayjs/locale/fr'),
  en: () => import('dayjs/locale/en'),
}

function loadLocale(language: string) {
  (locales as any)[language]().then(() => dayjs.locale(language))
}