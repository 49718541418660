<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $t('p.settings.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-list>
        <ion-item>
          <ion-label class="ion-text-wrap">
            {{ $t('p.settings.notif.label') }}
            <p>{{ $t('p.settings.notif.legend') }}</p>
          </ion-label>
          <ion-toggle slot="end" :checked="notificationsEnabled" @ionChange="enableNotificationsChanged"></ion-toggle>

        </ion-item>
      </ion-list>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle,
  IonToolbar, IonList, IonItem, IonLabel, IonToggle, onIonViewDidEnter,
} from '@ionic/vue';
import { ref } from 'vue';
import { subscribeToPush, isSubscribedToPush, unsubscribeFromPush } from '@/stores/user.store';
import { useAuthStore } from '@/stores/auth.store';


const auth = useAuthStore();
const notificationsEnabled = ref(false);
let initialized = false;

const enableNotificationsChanged = (e: any) => {
  //do not do anything before initialization to prevent auto-subscribe
  if (!initialized) return;

  const enabled = e.detail.checked;
  if (!(auth.userId)) {
    return;
  }

  if (enabled) {
    //subscribe
    subscribeToPush(auth.userId)
  } else {
    //Remove subscription
    unsubscribeFromPush(auth.userId)

  }
}

//load inital data when entering the tab
onIonViewDidEnter(async () => {
  if (auth.userId) {
    notificationsEnabled.value = await isSubscribedToPush(auth.userId);
    //need to enqueue the initialized flag because otherwise, it is changed before the change event
    setTimeout(() => initialized = true, 0);
  }
});

</script>




<style scoped></style>