<template>
    <ion-page>
        <ion-content>
            <div id="logo-wrapper">
                <picture>
                    <source srcset="assets/img/PeopleForce_Logo_DarkBG.png" media="(prefers-color-scheme:dark)">
                    <img src="assets/img/PeopleForce_Logo_WhiteBG.png" />
                </picture>
            </div>
            <div ref="step1" id="step1">
                <h1>{{ $t('p.login.title') }}</h1>
                <div class="form-wrapper">
                    <ion-item>
                        <ion-label position="floating">{{ $t('p.login.input-lb') }}</ion-label>
                        <ion-input type="text" inputMode='tel' autocapitalize="none" v-model="identifier"></ion-input>
                    </ion-item>

                </div>
                <div id="bt-wrapper">
                    <ion-button expand="block" @click="onIdentify">{{ $t('p.login.bt') }}</ion-button>
                </div>
            </div>

            <div ref="step2" id="step2">
                <h1>{{ $t('p.login.code-title') }}</h1>
                <div class="form-wrapper">
                    <ion-item>
                        <ion-label position="floating">{{ $t('p.login.code-lb') }}</ion-label>
                        <ion-input type="text" name="Code" autocapitalize="none" v-model="code"></ion-input>
                    </ion-item>

                </div>
                <div id="bt-wrapper">
                    <ion-button expand="block" @click="onLogin">{{ $t('p.login.code-bt') }}</ion-button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>



<script lang="ts" setup>
import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, toastController, loadingController } from '@ionic/vue';
import { ref } from 'vue';
import { useI18n } from "vue-i18n";
import { useAuthStore } from '@/stores/auth.store';

/*
there are 2 steps for login: _identify_ is used to specify the identifier phone nb, _login_ to specify the OTC
*/
const step1 = ref(null);
const step2 = ref(null);
let personnelId = -1;
const identifier = ref(''); //need to make a reactive store that can be exported and updated
const code = ref('');
const { t } = useI18n();
const auth = useAuthStore();

/**
 * _identify_ form submission callback
 * switch the form state to _login_ if the step is succesful
 */
const onIdentify = async function () {

    const loading = await loadingController.create({
        message: t('p.login.load-identify')
    });

    loading.present();

    const result = await auth.identify(identifier.value);
    loading.dismiss();

    if (result && result.status == 1 && result.personnelId && result.personnelId > 0) {
        personnelId = result.personnelId;
        (step1.value as any).style.display = 'none';
        (step2.value as any).style.display = 'block';
    } else {
        (step1.value as any).style.display = 'block';
        (step2.value as any).style.display = 'none';

        const toast = await toastController.create({
            message: t('p.login.unknown-user-id'),
            duration: 5000,
            color: 'warning',
        });
        toast.present();
    }
}

/**
 * _login_ form submission callback
 * if the login fails, reactivate the form, otherwise redirect to root page
 */
const onLogin = async function () {
    const loading = await loadingController.create({
        message: t('p.login.load-code'),
    });

    loading.present();

    const result = await auth.login(parseInt(code.value), personnelId);
    loading.dismiss();

    if (result && result.token) {
        console.log('SUCCESS LOGGED');
        // const navCtrl: HTMLIonRouterElement = await (this.nav as any).componentOnReady();
        // navCtrl.push('/');
        window.location.href = '/';
    } else {
        
        const toast = await toastController.create({
            message: t('p.login.invalid-otc'),
            duration: 5000,
            color: 'warning',
        });
        toast.present();
        console.log('FAIL LOGGED');
    }
}

</script>

<style scoped>
#logo-wrapper {
    margin: 100px 20px 50px 20px;
}

.form-wrapper {
    margin: 15px;
}

#bt-wrapper {
    margin: 0px 12px;
}

h1 {
    margin-left: 15px;
}

#step2 {
    display: none;
}
</style>