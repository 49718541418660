<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/" :text="$t('g.back')"></ion-back-button>
        </ion-buttons>
        <ion-title style="text-transform: capitalize;">{{ dayjs(props.start).format('dddd D MMMM') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-segment :value="display" @ionChange="displayChanged">
        <ion-segment-button value="previous">
          {{ $t('g.previous') }}
        </ion-segment-button>
        <ion-segment-button value="current">
          {{ $t('g.current') }}
        </ion-segment-button>
        <ion-segment-button value="next">
          {{ $t('g.next') }}
        </ion-segment-button>
      </ion-segment>



      <div v-for="[key, allocations] in map.entries()" :key="key">
        <div style="margin-left: 21px;">
          <h5 style="font-weight:bold">{{ lineNames.get(key) ? lineNames.get(key)![0] : '' }}</h5>
          <p style="font-size: 14px; color: gray; margin-top: 0;" v-if="allocations.length > 0">
            <i class="fal fa-clock fa-fw"></i> {{ dayjs(allocations[0].start).format('L') }} {{ allocations[0].time }}
          </p>
        </div>
        <ion-list>
          <ion-item v-for="(c, idx) in allocations" :key="idx">
            <ion-label>
              <p style="color:var(--color)">
                <i class="fal fa-user-helmet-safety fa-fw"></i> {{ c.name }}
              </p>
              <p style="padding-left: 22px">{{ c.userName }} ({{ dayjs(c.start).format('LT') }} - {{ dayjs(c.end).format('LT') }})</p>
            </ion-label>
          </ion-item>
          <ion-item v-if="allocations.length <= 0"><ion-note>{{ $t('p.allocation-day.line-empty') }}</ion-note></ion-item>
        </ion-list>
      </div>

      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || map.size > 0 ? 'none' : 'block' }" icon="fa-user-helmet-safety">
        {{ $t('p.allocation-day.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons,
  IonTitle, IonBackButton, IonNote,
  IonToolbar, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton
} from '@ionic/vue';
import { ref } from 'vue';
import EmptyState from './components/EmptyState.vue'
import ListSkeletton from './components/ListSkeletton.vue'
import dayjs from 'dayjs';
import { useAuthStore } from '@/stores/auth.store';
import { getApi } from '@/helpers/api';
import { PeriodAllocationsApi, DtoAllocation } from 'ppf-api-client';

//route parameters
const props = defineProps({
  /**
   * day for which details should be displayed
   */
  start: String,
})

const loading = ref(true);
const display = ref<'current' | 'previous' | 'next'>('current');
//the displayed allocation details
const map = ref<Map<number, DtoAllocation[]>>(new Map<number, DtoAllocation[]>());
//keep the map of current allocations so we don't have to query it again
let currentMap: Map<number, DtoAllocation[]> = new Map<number, DtoAllocation[]>();
//keep the line names indexed on id to be able to show the list headers
let lineNames: Map<number, [string, Date]> = new Map<number, [string, Date]>();

const service = getApi(PeriodAllocationsApi);
const auth = useAuthStore();

//load initial data
if (props.start && service) {
  const start = new Date(props.start);

  service.periodAllocationsGetDetails(auth.userId, start).then(r => {
    r.forEach(r1 => {
      if (r1.length > 0 && r1[0].lineId && r1[0].line && r1[0].start ) {
        lineNames.set(r1[0].lineId, [r1[0].line, r1[0].start]);
        currentMap.set(r1[0].lineId, r1); //keep it for later
        map.value.set(r1[0].lineId, r1);  //use it for view
      }
    });
    loading.value = false;
  });
}

/**
 * ion-segment change handler
 * @param e 
 */
const displayChanged = (e: any) => {
  display.value = e.target.value;

  //switch to 'current': just reload saved data from initial load
  if (display.value == 'current') {
    for (const key of currentMap.keys()) {
      if (currentMap.has(key)) {
        // eslint-disable-next-line
        map.value.set(key, currentMap.get(key)!);
      }
    }

  } else {
    if (props.start && service) {
      //switch to 'previous': get previous allocations for all lines up to current's start
      if (display.value == 'previous') {
        for (const entry of currentMap.entries()) { //entry: [0: key, 1: AllocationItem[]]
          if (entry[1].length >= 0) {
            //find the person with the earliest  start
            //Note: no need to get a particular item anymore
            const latest = entry[1][0]; //.reduce((acc, cur) => acc.start < cur.start ? acc : cur);
            if (!latest.lineId || !latest.shiftOccurenceId || !latest.start || !latest.end) {
              console.warn('Cannot retrieve detailed planning: missing information');
              continue;
            }
            service.periodAllocationsGetPlanningDetailBefore(latest.lineId, latest.shiftOccurenceId, latest.start, latest.end).then(r => {
              map.value.set(entry[0], r);
            });
          }
        }

        //switch to 'next': get next allocations for all lines after current's end
      } else if (display.value == 'next') {
        for (const entry of currentMap.entries()) { //entry: [0: key, 1: AllocationItem[]]
          if (entry[1].length >= 0) {
            //find the person with the latest end
            //Note: no need to get a particular item anymore
            const latest = entry[1][0]; //.reduce((acc, cur) => acc.start > cur.start ? acc : cur);
            if (!latest.lineId || !latest.shiftOccurenceId || !latest.start || !latest.end) {
              console.warn('Cannot retrieve detailed planning: missing information');
              continue;
            }
            service.periodAllocationsGetPlanningDetailAfter(latest.lineId, latest.shiftOccurenceId, latest.start, latest.end).then(r => {
              map.value.set(entry[0], r);
            });
          }
        }
      }
    }
  }
}
</script>

<style scoped></style>
