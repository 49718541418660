import { createConfiguration, ServerConfiguration, RequestContext, Configuration, ResponseContext } from 'ppf-api-client';
import { Constants } from './constants';

/**
 * Api factory
 * 
 * get an instance of API controller.
 * @param type The class type to retrieve
 * @returns an instance of API controller
 */
export function getApi<T>(type: { new(configuration: Configuration): T }): T {
    const auth = {
        getName: () => 'bearertoken',
        applySecurityAuthentication: (context: RequestContext) => context.setHeaderParam('Authorization', `Bearer ${localStorage.getItem('id_token')}`)
    }
    const configuration = createConfiguration({
        baseServer: new ServerConfiguration(process.env.VUE_APP_API_URL, {}), // First server is default
        authMethods: { default: auth }, // No auth is default
        promiseMiddleware: [new AddAppNameMiddleware()],
    });

    return new type(configuration);
}



class AddAppNameMiddleware  {
    pre(context: RequestContext): Promise<RequestContext> {
        context.setHeaderParam('ppf-app-name', Constants.APP_NAME);
        return Promise.resolve(context)
    }
    post(context: ResponseContext): Promise<ResponseContext> {
        return Promise.resolve(context)
    }

}