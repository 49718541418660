<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $t('p.counter.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-list>
        <ion-item v-for="c in counters" v-bind:key="c.id">
          <i slot="start" class="fa fa-circle fa-sm fa-fw" :style="{ color: c.color || '#000' }"></i>
          <ion-label>
            <h2>{{ c.name }}</h2>
            <p>{{ c.formattedValue }}</p>
          </ion-label>
        </ion-item>
      </ion-list>

      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || counters.length > 0 ? 'none' : 'block' }" icon="fa-calculator">
        {{ $t('p.counter.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle,
  IonToolbar, IonList, IonItem, IonLabel, onIonViewDidEnter
} from '@ionic/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import EmptyState from './components/EmptyState.vue'
import ListSkeletton from './components/ListSkeletton.vue'
import { getApi } from '@/helpers/api';
import { PeriodCountersApi, DtoCounter } from 'ppf-api-client';


const counters = ref<DtoCounter[]>([]);
const loading = ref(true);

const service = getApi(PeriodCountersApi);
const auth = useAuthStore();

//load inital data when entering the tab
onIonViewDidEnter(() => {
  loading.value = true;
  counters.value = [];

  service.periodCountersByPersonnel(auth.userId, new Date()).then(r => {
    counters.value = r.sort((a, b) => a.index - b.index);
    loading.value = false;
  });
});


</script>

<style scoped></style>
