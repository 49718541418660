<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title @click="scrollToTop">{{ $t('p.allocation.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content ref="content">
      <ion-refresher @ionRefresh="onRefresh" slot="fixed">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-list>
        <ion-item-group v-for="c in weeks" v-bind:key="c.number">
          <ion-item-divider>
            <ion-label>{{ $t('p.allocation.week-header', {
              week: c.number, year: c.start ? c.start.getFullYear() : 'N/A'
            }) }}</ion-label>
          </ion-item-divider>





          <ion-item v-for="d in c.days" v-bind:key="d.day.dateTime!.toISOString()" :button="d.allocations.length > 0"
            :detail="false" @click="goToDetails(d)">

            <ion-label>

              <i class="fal fa-chevron-right fa-fw" style="float:right; color: gray; font-size: .8em;"
                v-if="d.allocations.length > 0"></i>
              <h2 style="text-transform: capitalize;">{{ dayjs(d.day.dateTime).format('dddd D MMMM') }}</h2>


              <div v-for="(e, idx) in d.allocations" :key="idx">
                <p>
                  <i class="fal fa-user-helmet-safety fa-fw"></i> {{ e.name }}
                  <span style="float: right">{{ e.isException ? rangeTimeText(e.start, e.end, 'HH:mm') : e.time
                    }}</span>
                </p>
                <p style="color: gray;padding-left: 22px">
                  {{ e.workshop }} \ {{ e.line }}
                </p>
                <p v-if="e.comment" style="font-style: italic;color: gray">
                  <i class="fal fa-comment fa-fw"></i>
                  {{ e.comment }}
                </p>
              </div>




              <div class="teams" v-if="d.teams && d.teams.length > 0">
                <i class="fal fa-clock fa-fw"></i>
                <ul>
                  <template v-for="e in d.teams" v-bind:key="e.name">
                    <li v-for="s in e.shifts" v-bind:key="s">
                      {{ s }}
                    </li>
                  </template>
                </ul>
              </div>

              <p v-for="(e, idx) in d.events" :key="idx">
                <span class="label"
                  :style="{ backgroundColor: e.typeColor || '#000', color: getForegroundForBackground(e.typeColor || '#000') }">
                  {{ e.type }}
                </span>
                {{ getEventDateRangeDisplay(e) }}
                <!-- <span v-if="e.toBeValidated">{{ $t('p.allocation.to-be-validated') }}</span> --><!--TODO GMA missing in API!-->
              </p>

              <div v-for="(e, idx) in d.transferts" :key="idx">
                <span class="label"
                  :style="{ backgroundColor: e.typeColor || '#000', color: getForegroundForBackground(e.typeColor || '#000') }">
                  {{ e.type }}
                </span>
                <p v-if="e.comment" style="font-style: italic;color: gray">
                  <i class="fal fa-comment fa-fw"></i>
                  {{ e.comment }}
                </p>
              </div>

              <div v-for="(e, idx) in d.assignations" :key="idx">
                <span class="label"
                  :style="{ backgroundColor: e.typeColor || '#000', color: getForegroundForBackground(e.typeColor || '#000') }">
                  {{ e.type }}
                </span>
                <p v-if="e.comment" style="font-style: italic;color: gray">
                  <i class="fal fa-comment fa-fw"></i>
                  {{ e.comment }}
                </p>
              </div>

              <div v-for="(e, idx) in d.reservations" :key="idx">
                <span class="label"
                  :style="{ backgroundColor: e.typeColor || '#000', color: getForegroundForBackground(e.typeColor || '#000') }">
                  {{ e.type }}
                </span>
                <p v-if="e.comment" style="font-style: italic;color: gray">
                  <i class="fal fa-comment fa-fw"></i>
                  {{ e.comment }}
                </p>
              </div>

            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>

      <ion-infinite-scroll @IonInfinite="loadMoreData">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <list-skeletton :style="{ display: loading ? 'block' : 'none' }"></list-skeletton>

      <empty-state :style="{ display: loading || weeks.length > 0 ? 'none' : 'block' }" icon="fa-user-helmet-safety">
        {{ $t('p.allocation.empty') }}</empty-state>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonContent, IonHeader, IonPage, IonButtons, IonMenuButton,
  IonTitle, IonItemGroup, IonItemDivider, IonInfiniteScroll, IonInfiniteScrollContent,
  IonToolbar, IonList, IonItem, IonLabel, IonRefresher, IonRefresherContent, InfiniteScrollCustomEvent, RefresherCustomEvent, onIonViewDidEnter,
} from '@ionic/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import EmptyState from './components/EmptyState.vue'
import ListSkeletton from './components/ListSkeletton.vue'
import dayjs from 'dayjs';
import { getForegroundForBackground } from '@/helpers/colors';
import { useRouter } from 'vue-router'
import { rangeTimeText } from '@/helpers/date';
import { useI18n } from "vue-i18n";
import { getApi } from '@/helpers/api';
import { PeriodAllocationsApi, PersonPlanningWeek, PersonPlanningDay, DtoCalendarItem } from 'ppf-api-client';

const content = ref<InstanceType<typeof IonContent> | null>(null);
const weeks = ref<PersonPlanningWeek[]>([]);
const loading = ref(true);
const { t } = useI18n();
const service = getApi(PeriodAllocationsApi);
const auth = useAuthStore();
const router = useRouter();



//load inital data when entering the tab
onIonViewDidEnter(() => {
  loading.value = true;
  weeks.value = [];

  refresh().then(() => {
    loading.value = false;
  });
});


/**
 * go to details callback
 * navigate to allocation details for the clicked cell if there are any allocations
 * @param day the allocation day clicked
 */
const goToDetails = function (day: PersonPlanningDay): void {
  if (day.allocations && day.allocations.length > 0 && day.day && day.day.dateTime) {
    router.push(`/allocationsDay/${day.day.dateTime.toISOString()}`);
  }
}

/**
 * Get event range display
 * there are 4 possible cases:
 * - event is all day
 * - start & end are on the same day
 * - start is today and end is another day
 * - end is today and start is another day
 * @param event 
 */
const getEventDateRangeDisplay = function (event: DtoCalendarItem): string {
  if (event.allDay) {
    return '';
  }
  const start = dayjs(event.start);
  const end = dayjs(event.end);
  const now = dayjs(Date.now());
  if (start.isBefore(end, 'day')) {
    if (start.isSame(now, 'day')) {
      return t('g.time.since', { date: start.format('HH:mm') });
    }
    else {
      return t('g.time.until', { date: end.format('HH:mm') });
    }
  } else {
    return t('g.time.range', { since: start.format('HH:mm'), until: end.format('HH:mm') });
  }
}

/**
 * refresh callback
 * @param e 
 */
const onRefresh = async (e: RefresherCustomEvent) => {
  await refresh();
  e.target.complete();
};

/**
 * refresh the displayed list
 * 
 * reinitialize the whole list by refetching data starting now
 */
const refresh = async () => {
  if (service) {
    const items = await service.periodAllocationsWeeksFromDate(auth.userId, new Date(), 4, true);
    //#33276 remove days before the current day
    if (items.weeks.length > 0 && items.weeks[0].days.length > 0) {
      const now = new Date();
      const thisMorning = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      items.weeks[0].days = items.weeks[0].days.filter(d => d.day && d.day.dateTime && d.day.dateTime > thisMorning);
    }
    weeks.value = items.weeks && items.weeks.length > 0 ? items.weeks : [];
  }
};

/**
 * scroll to top callback
 */
const scrollToTop = () => (content.value as any)?.$el.scrollToTop();

/**
 * load 2 more weeks of data and append them to the list
 * @param e 
 */
const loadMoreData = async (e: InfiniteScrollCustomEvent) => {
  if (service && weeks.value.length > 0) {
    const items = await service.periodAllocationsWeeksFromDate(auth.userId, dayjs(new Date()).add(weeks.value.length * 7, 'days').toDate(), 2, true);
    if (items.weeks.length > 0) {
      weeks.value.push(...items.weeks);
    }
  }
  e.target.complete();
}


</script>








<style scoped>
.right-label {
  text-align: right;
}

h2 {
  font-weight: bold;
  margin-bottom: 5px
}

.label {
  padding: 3px 5px;
  border-radius: 3px;
  margin-right: 5px;
  line-height: 20px;
  font-size: 14px;
  /* font-size: .7em; */
}

div,
p {
  margin-top: 5px
}

.teams {
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-size: 13px;
}

.teams>ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.teams>ul>li {
  display: inline;
}

.teams>ul>li:not(:first-child):before {
  content: ' + ';
}
</style>
